<template>
  <div class="main-layout"
    :class="{
      'main-layout_transparent': headerTransparent,
      'main-layout_fluid': fluid,
    }"
  >
    <header class="main-layout__header">
      <div
          v-if="centeredEasytipLogo"
          class="main-layout__header-content main-layout__header-content--centered"
      >
        <img
            class="main-layout__logo main-layout__logo--large"
            src="@/assets/logo-horizontal.svg"
            alt="easytip logo"
        >
      </div>

      <div v-else class="main-layout__header-content">
        <div class="main-layout__logo-wrapper main-layout__logo-wrapper-left">
          <img
            class="main-layout__logo"
            src="@/assets/logo-horizontal.svg"
            alt="easytip logo"
          >
        </div>

        <div
          v-if="companyLogo"
          class="main-layout__logo-wrapper"
        >
          <user-avatar
            :payout-id="payoutId"
            class="main-layout__company-logo"
            size="extra-small"
            borderless
          />
        </div>

        <div
          v-if="partnerIdValue && isPartnerAvatarLoaded"
          class="main-layout__logo-wrapper main-layout__logo-wrapper-right"
        >
          <user-avatar
            :payout-id="payoutId"
            :partner-id="partnerIdValue"
            class="main-layout__partner-logo"
            size="extra-small"
            borderless
            rectangle
            @status="handlePartnerAvatarValid"
          />
        </div>

        <!-- Hide temporary -->
        <div v-if="false" class="main-layout__actions">
          <ui-locale
            class="main-layout__locale"
            @update:locale="onLocaleChange"
          />
          <ui-icon-button
            v-if="showBackButton"
            name="left-arrow"
            size="28px"
            class="main-layout__back-btn"
            @click="goToLocationMenu"
          />
        </div>
      </div>
    </header>

    <div
        class="main-layout__content"
        :class="{ 'main-layout__content_centered': centered }">
      <slot></slot>
    </div>

    <bottom-section
      :no-social-icons="noSocialIcons"
      :follow-us-block="followUsBlock"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import UiLocale from '@/components/UiLocale.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import UiIconButton from '@/components/UiIconButton.vue';
import BottomSection from '@/components/BottomSection.vue';

const emit = defineEmits(['update:locale']);

const props = defineProps({
  payoutId: { type: String },
  centered: { type: Boolean },
  companyLogo: { type: Boolean },
  partnerId: {
    type: String,
    required: false,
  },
  noSocialIcons: {
    type: Boolean,
    default: false,
  },
  followUsBlock: { type: Boolean },
  headerTransparent: { type: Boolean },
  fluid: { type: Boolean },
  centeredEasytipLogo: { type: Boolean },
});

const store = useStore();
const router = useRouter();
const route = useRoute();

const isPartnerAvatarLoaded = ref(true);
const partnerIdValue = ref('');

const showBackButton = computed(() => {
  return route.query.preview
    ? store.state.user.location.isOnlineMenuPreviewExists
    : store.state.user.location.isOnlineMenuPublished;
});

const onLocaleChange = (payload) => {
  emit('update:locale', payload);
};

const goToLocationMenu = () => {
  router.push({ name: 'location', query: route.query });
};

const handlePartnerAvatarValid = (payload) => {
  isPartnerAvatarLoaded.value = payload;
};

watch(() => props.partnerId, (value) => {
  if (value) {
    partnerIdValue.value = value;
  }
}, { immediate: true });
</script>
