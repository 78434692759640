<template>
  <ui-button
    v-bind="$attrs"
    google-pay
    :disabled="isLoading || !totalAmount"
    :is-loading="isLoading"
    aria-label="Google pay button"
    @click.prevent="submitHandler"
  />
</template>
<script setup>
import UiButton from '@/components/UiButton.vue';
import api from '@/api/index';
import {
  computed,
  getCurrentInstance,
  ref,
  toRefs,
} from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  listPayment: Array,
  feeChecked: Boolean,
  currency: String,
  amount: Number,
  totalAmount: Number,
  feeAmount: Number,
  totalTipsFee: Number,
  groupId: String,
  workplacePayoutId: String,
  backUrl: Object,
  paymentId: String,
  status: String,
  billId: String,
  billCustomerInfo: Object,
  successUrl: String,
  errorUrl: String,
  isTips: Boolean,
});

const {
  isTips,
} = toRefs(props);

const router = useRouter();
const currentInstance = getCurrentInstance();
const { $amplitude } = currentInstance.appContext.config.globalProperties;

const isLoading = ref(false);

const isQrBillPayment = computed(() => {
  return Boolean(props.billId);
});

const paymentPayload = computed(() => {
  const statusUrls = {
    successUrl: props.successUrl,
    errorUrl: props.errorUrl,
  };

  const tipsDetails = {
    listPayment: props.listPayment,
    currency: props.currency,
    amount: +props.totalAmount,
    feeAmount: props.feeAmount,
    feeChecked: props.feeChecked,
    workplacePayoutId: props.workplacePayoutId,
    groupId: props.groupId,
  };

  if (isQrBillPayment.value) {
    const billPaymentData = {
      billId: props.billId,
      customerInfo: props.billCustomerInfo,
    };

    if (isTips.value) {
      return {
        ...statusUrls,
        ...billPaymentData,
        tipsDetails,
      };
    }

    return {
      ...statusUrls,
      ...billPaymentData,
    };
  }

  return {
    ...statusUrls,
    ...tipsDetails,
  };
});

const redirectToWrongPayment = () => {
  $amplitude.event('WRONG_PAYERMAX_PAYMENT_GOOGLE_PAY', paymentPayload.value);

  const backUrl = router.resolve({
    name: 'home',
    params: {
      locationPayoutId: props.workplacePayoutId,
    },
    query: {
      staffId: props.listPayment.map(({ payoutId }) => payoutId),
    },
  })?.href;

  window.location.href = router.resolve({
    name: 'payment-failed',
    query: {
      backUrl,
      payermax: true,
    },
  }).href;
};

const redirectToStatusPage = ({ status, paymentId }) => {
  window.location.href = router.resolve({
    path: `${props.backUrl.locationId}/${status}`,
    query: {
      ...props.backUrl.query,
      paymentId,
      rate: props.backUrl.rate,
      isPayermaxGooglePayPayment: true,
      amount: props.totalAmount,
      feeAmount: props.feeAmount,
      customerEmail: props.backUrl.customerEmail,
    },
  }).href;
};

const submitHandler = async () => {
  try {
    isLoading.value = true;

    const { data } = await api.createPayermaxGooglePayPayment(paymentPayload.value, isQrBillPayment.value);

    if (data?.redirectUrl) {
      window.location.href = data.redirectUrl;
    } else {
      redirectToStatusPage({
        paymentId: data.id,
        status: 'error',
      });
    }
  } catch (e) {
    redirectToWrongPayment();
  } finally {
    isLoading.value = false;
  }
};
</script>
