<template>
  <ui-card>
    <div class="bill-info">
      <div class="bill-info__amount">
        <div class="bill-info__amount-label">
          Amount to pay:
        </div>

        <div class="bill-info__amount-value">
          {{ billAmount }}
        </div>
      </div>

      <bill-tax-list :items="billTaxList" />

      <bill-discount
        v-if="billDiscount.percentage"
        :data="billDiscount"
        :currency="currency"
      />

      <bill-deposit-info
        v-if="isDepositUnpad || isWaitingForFullPayment"
        :items="billDepositList"
      />

      <ui-button
        transparent
        class="bill-info__button"
        @click="handleShowDetails"
      >
        Show full receipt
      </ui-button>
    </div>

    <bill-info-details
      v-model="showDetails"
      :currency="currency"
      :items="billServiceList"
      :is-deposit="isDepositUnpad || isWaitingForFullPayment"
      :bill-amount="billAmount"
      :bill-tax-list="billTaxList"
      :bill-discount="billDiscount"
      :bill-deposit="billDepositList"
    />
  </ui-card>
</template>

<script setup>
import { ref, computed, toRefs } from 'vue';
import Money from '@/class/Money';
import UiCard from '@/components/UiCard.vue';
import UiButton from '@/components/UiButton.vue';
import BillInfoDetails from './BillInfoDetails.vue';
import BillTaxList from './BillTaxList.vue';
import BillDiscount from './BillDiscount.vue';
import BillDepositInfo from './BillDepositInfo.vue';

const props = defineProps({
  data: Array,
  currency: Object,
});

const { data, currency } = toRefs(props);

const showDetails = ref(false);

const status = computed(() => {
  return data.value?.status;
});

const isDepositUnpad = computed(() => {
  return status.value === 'DEPOSIT_UNPAID';
});

const isWaitingForFullPayment = computed(() => {
  return status.value === 'WAITING_FOR_FULL_PAYMENT';
});

const billDiscount = computed(() => ({
  percentage: data.value?.discount,
  amount: data.value?.totalWithoutDiscount?.amount || 0,
}));

const billAmount = computed(() => {
  if (isDepositUnpad.value || isWaitingForFullPayment.value) {
    return new Money(data.value?.amountToPay?.amount || 0, currency.value);
  }

  return new Money(data.value?.total?.amount || 0, currency.value);
});

const billTaxList = computed(() => {
  return data.value?.taxes?.reduce((acc, item) => {
    const name = item.name.split(' ').join('_').toLowerCase();

    return [
      ...acc,
      {
        id: name,
        label: item.name,
        value: new Money(item.value.amount, currency.value),
      },
    ];
  }, []);
});

const billServiceList = computed(() => {
  if (!data.value?.services?.length) {
    return [];
  }

  return data.value.services.map((item) => ({
    ...item,
    price: new Money(item.price.amount, currency.value),
    total: new Money(item.total.amount, currency.value),
  }));
});

const billDepositList = computed(() => {
  return [
    {
      id: 'order-amount',
      label: 'Order amount',
      value: new Money(data.value?.total?.amount || 0, currency.value),
    },
    {
      id: 'order-deposit',
      label: isDepositUnpad.value
        ? `Deposit, ${data.value.depositInfo?.depositPercent}%`
        : `Paid amount, ${data.value.depositInfo?.depositPercent}%`,
      value: new Money(data.value.depositInfo?.depositAmount?.amount || 0, currency.value),
    },
  ];
});

const handleShowDetails = () => {
  showDetails.value = true;
};
</script>
