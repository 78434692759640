<template>
  <form
    id="ryft-pay-form"
    class="ryft-pay-form"
    :class="{
      'ryft-pay-form--loading': frameLoading,
      'ryft-pay-form--disabled': isDisabled,
    }"
    :style="{ 'min-height': actionsHeight ? `${actionsHeight}px !important` : '' }"
    @submit="submitHandler"
  >
    <div class="ryft-pay-form__loading-wrapper">
      <ui-spinner
        v-if="frameLoading"
        class="ryft-pay-form__loading"
      />
    </div>
    <div v-if="errorText" class="ryft-pay-form__error">
      {{ errorText }}
    </div>
    <ui-button
      class="ryft-pay-form__submit-btn"
      :disabled="isDisabled || confirmLoading"
      wide
      :is-loading="confirmLoading || frameLoading"
      @click="submitHandler"
    >
      <span>
        <ui-money :value="totalMoney" /> Pay by card
      </span>
    </ui-button>
  </form>
</template>
<script setup>
/* eslint-disable no-undef */
import {
  computed,
  ref,
  toRefs,
  watch,
} from 'vue';
import Money from '@/class/Money';
import UiMoney from '@/components/UiMoney.vue';
import UiButton from '@/components/UiButton.vue';
import UiSpinner from '@/components/UiSpinner.vue';
import { useStore } from 'vuex';
import api from '@/api';
import { useOvertippingMixin } from '@/mixins/overtipping-mixin';

const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
  DECLINED_3DS: 'DECLINED_3DS',
  REFUNDED: 'REFUNDED',
  UNDEFINED: 'UNDEFINED',
  MANUAL_CHECK: 'MANUAL_CHECK',
};

const props = defineProps({
  amount: Number,
  totalAmount: Number,
  feeAmount: Number,
  currencyInfo: String,
  listPayment: Array,
  feeChecked: Boolean,
  workplacePayoutId: String,
  groupId: String,
  isMultiPayPage: Boolean,
  successUrl: String,
  errorUrl: String,
  isDisabled: Boolean,
  isTips: Boolean,
  billId: String,
  billAmount: Number,
  billCustomerInfo: Object,
  backUrl: Object,
});

const {
  amount,
  totalAmount,
  isDisabled,
  feeChecked,
  isTips,
  billId,
  billAmount,
  billCustomerInfo,
} = toRefs(props);

const store = useStore();

const { overtippingConfirmHandler } = useOvertippingMixin();

const formDisabled = ref(true);
const frameLoading = ref(true);
const errorText = ref('');
const confirmLoading = ref(false);
const sessionKey = ref('');

const actionsHeight = ref(0);

const isBill = computed(() => {
  return Boolean(props.billId);
});

const isQrBillWithTips = computed(() => {
  return isBill.value && isTips.value;
});

const isQrBillWithoutTips = computed(() => {
  return isBill.value && !isTips.value;
});

const totalMoney = computed(() => {
  let baseAmount = totalAmount.value;

  if (isBill.value) {
    baseAmount = billAmount.value;
  }

  return new Money(baseAmount, store.getters['user/currency']);
});

const getSessionKey = async () => {
  const tips = {
    currency: props.currencyInfo,
    amount: +totalAmount.value,
  };

  let payload = tips;

  if (isQrBillWithTips.value) {
    payload = {
      billId: billId.value,
      tips,
    };
  }

  if (isQrBillWithoutTips.value) {
    payload = {
      billId: billId.value,
    };
  }

  const { data } = await api.getRyftCardSession(payload, billId.value);

  return data;
};

const paymentPayload = computed(() => {
  const tipsDetails = {
    listPayment: props.listPayment,
    feeChecked: props.feeChecked,
    currency: props.currencyInfo,
    amount: props.amount,
    // amount: +totalAmount.value,
    feeAmount: props.feeAmount,
    workplacePayoutId: props.workplacePayoutId,
    groupId: props.groupId,
  };

  if (isBill.value) {
    const billPaymentData = {
      billId: billId.value,
      customerInfo: billCustomerInfo.value,
    };

    if (isTips.value) {
      return {
        ...billPaymentData,
        tipsDetails,
      };
    }

    return billPaymentData;
  }

  return tipsDetails;
});

const successRyftHandler = async (paymentSession) => {
  try {
    const { data } = await api.createRyftCardPayment({
      ...paymentPayload.value,
      successUrl: props.successUrl,
      errorUrl: props.errorUrl,
      sessionKey: sessionKey.value,
      customerCardToken: paymentSession.id,
    }, isBill.value);

    const walletType = paymentSession?.paymentMethod?.wallet?.type || null;

    let urlPostfix = `&paymentId=${data.id}&isGooglePay=${walletType === 'GooglePay'}&isApplePay=${walletType === 'ApplePay'}`;

    const email = billCustomerInfo.value?.email
      || props.backUrl.customerEmail;

    if (email) {
      urlPostfix = urlPostfix.concat(`&customerEmail=${email}`);
    }

    if (data && data.status === PAYMENT_STATUS.PENDING && data?.id) {
      window.location.href = `${props.successUrl}${urlPostfix}`;
    } else {
      window.location.href = `${props.errorUrl}${urlPostfix}`;
    }
  } catch (e) {
    console.log(e);
  } finally {
    confirmLoading.value = false;
  }
};

function handlePaymentResult(paymentSession) {
  if (
    paymentSession.status === 'Approved'
    || paymentSession.status === 'Captured'
  ) {
    successRyftHandler(paymentSession);
    return;
  }
  if (paymentSession.lastError) {
    const userFacingError = Ryft.getUserFacingErrorMessage(paymentSession.lastError);
    console.log(userFacingError);
    errorText.value = userFacingError;
  }
}

const submitHandler = async () => {
  if (formDisabled.value) {
    errorText.value = 'Enter valid card details';

    return;
  }

  if (!await overtippingConfirmHandler({
    amountSum: amount.value,
    submitMoney: new Money(amount.value, store.getters['user/currency']).toString(),
  })) {
    return;
  }

  confirmLoading.value = true;

  await Ryft.attemptPayment({ customerEmail: 'ryft@easytip.net' })
    .then((paymentSession) => {
      handlePaymentResult(paymentSession);
    })
    .catch((error) => {
      console.log(error);
      errorText.value = error;
    });

  confirmLoading.value = false;
};

const resetError = () => {
  errorText.value = '';
};

const initRyft = async () => {
  actionsHeight.value = document.querySelector('#ryft-pay-form')?.offsetHeight || 0;

  frameLoading.value = true;

  const sessionData = await getSessionKey();
  sessionKey.value = sessionData.sessionKey;

  if (Ryft) {
    Ryft.init({
      publicKey: store.state.user.publicKey,
      clientSecret: sessionData.clientSecret,
      applePay: {
        merchantName: 'EasyTip',
        merchantCountryCode: 'GB',
        buttonConfiguration: {
          height: 56,
        },
      },
      googlePay: {
        merchantName: 'EasyTip',
        merchantCountryCode: 'GB',
        buttonConfiguration: {
          height: 56,
        },
      },
      style: {
        borderRadius: 2,
        borderColor: '#808080',
      },
    });

    Ryft.addEventHandler('walletPaymentSessionResult', (e) => {
      handlePaymentResult(e.paymentSession);
    });

    Ryft.addEventHandler('cardValidationChanged', (e) => {
      resetError();
      formDisabled.value = !e.isValid;
    });
  }

  frameLoading.value = false;
};

const installRyftHandler = async () => {
  if (typeof Ryft === 'undefined') {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embedded.ryftpay.com/v1/ryft.min.js';
    script.onload = initRyft;

    document.head.appendChild(script);
  } else {
    initRyft();
  }
};

watch(totalAmount, (newSum) => {
  if (isBill.value) {
    installRyftHandler();

    return;
  }

  // Не обновлять при сбросе комиссий до 0 во время смены сумм в Home
  if (feeChecked.value && amount.value === Number(totalAmount.value)) return;
  if (newSum) {
    installRyftHandler();
  }
}, { immediate: true });
</script>
<style lang="scss">
  .ryft-pay-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    min-height: 120px !important;
    width: 100%;

    &--loading {
      #ryft-pay-iframe,
      .ryft-pay-grid,
      .divider {
        opacity: 0;
        z-index: 1;
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    #ryft-pay-iframe {
      margin-bottom: auto;
    }

    &__loading {
      color: #808080;
      margin: 10px auto;
      position: initial;

      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        padding-bottom: 77px;
        pointer-events: none;
      }
    }

    &__error {
      color: #A01212;
      margin: 12px 0 0;
      font-size: 14px;
    }

    &__submit-btn {
      margin-top: 12px;
    }
  }
</style>
