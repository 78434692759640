<template>
  <div class="receipt-card">
    <div
      v-if="showSuccess"
      class="receipt-card__success"
    >
      The receipt has been sent<br>
      to your email!
    </div>

    <template v-else>
      <div class="receipt-card__title">
        Send the receipt to
      </div>

      <ui-input
        v-model.trim="email"
        small
        class="receipt-card__email"
        placeholder="Email"
        :error="errorMessage"
        @input="handleChange"
      />

      <ui-button
        small
        class="receipt-card__button"
        :disabled="!email || isLoading || errorMessage"
        :is-loading="isLoading"
        @click.prevent="send"
      >
        Send
      </ui-button>
    </template>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { getCurrentInstance, onMounted, ref } from 'vue';
import api from '@/api';
import { useEventTrackerMixin } from '@/mixins/event-tracker-mixin';
import UiInput from './UiInput.vue';
import UiButton from './UiButton.vue';

const props = defineProps({
  isLoading: Boolean,
  customerEmail: String,
  metaConfig: Object,
});

const emit = defineEmits(['send']);

const { t } = useI18n();
const currentInstance = getCurrentInstance();
const { $amplitude } = currentInstance.appContext.config.globalProperties;

const { eventTrackerNamePrefix } = useEventTrackerMixin();

const isLoading = ref(false);
const showSuccess = ref(false);
const email = ref('');

const isValid = (value) => {
  const emailRegexp = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i;

  if (!value || !emailRegexp.test(value)) {
    return t('validationErrors.email');
  }

  return true;
};

const { errorMessage, handleChange } = useField('comment', isValid);

const send = async () => {
  if (!props.metaConfig.paymentId) {
    return;
  }

  isLoading.value = true;

  try {
    await api.sendReceiptEmail({
      email: email.value,
      paymentId: props.metaConfig.paymentId,
      apiVersion: props.metaConfig.apiVersion,
    });

    $amplitude.event(
      `${eventTrackerNamePrefix.value}${props.metaConfig.name}_SEND_RECEIPT`,
      props.metaConfig.isMultipleTips,
    );

    showSuccess.value = true;
  } catch (e) {
    console.log(e);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  if (props.customerEmail) {
    email.value = props.customerEmail;
  }
});
</script>

<style lang="scss">
.receipt-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(255, 255, 255, 0.50);
  min-height: 184px;

  &__success {
    text-align: center;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }

  &__email {
    margin-top: 24px;
    min-height: 55px;
  }

  &__button {
    margin-left: auto;
    min-width: 155px;
  }
}
</style>
