<template>
  <div class="status-screen-socials">
    <div class="status-screen-socials__body">
      <div class="status-screen-socials__title">
        Support Fair Tipping
      </div>

      <div class="status-screen-socials__description">
        Follow us to explore {{ isUAE ? 'UAE' : 'UK' }}'s trending<br>places and hidden gems:
      </div>
    </div>

    <div class="status-screen-socials__footer">
      <a
        v-for="social in socials"
        :key="social.name"
        :href="social.url"
        target="_blank"
        class="status-screen-socials__link"
        :class="[`status-screen-socials__link--${social.name}`]"
        @click="track(social.name)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { FACEBOOK_LINK, INSTAGRAM_LINK, LINKEDIN_LINK } from '@/constants/socials';
import { useStore } from 'vuex';

const socials = [
  {
    name: 'instagram',
    url: INSTAGRAM_LINK,
  },
  {
    name: 'facebook',
    url: FACEBOOK_LINK,
  },
  {
    name: 'linkedin',
    url: LINKEDIN_LINK,
  },
];

const emit = defineEmits(['link-click']);

const store = useStore();

const track = (name) => {
  emit('link-click', name.toUpperCase());
};

const isUAE = computed(() => {
  return store.getters['user/isUAE'];
});

</script>

<style lang="scss">
.status-screen-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 24px 27px;
  border-radius: 20px;
  background: rgba(213, 215, 217, 0.50);

  &__title {
    font-size: 17px;
    line-height: 23px;
    font-weight: 700;
    text-align: center;
  }

  &__description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    opacity: 0.6;
    text-align: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    padding-bottom: 12px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 14px;
  }

  &__link {
    width: 37px;
    height: 37px;

    &--instagram {
      background-image: url('~@/assets/social/instagram-rounded.svg');
    }

    &--facebook {
      background-image: url('~@/assets/social/facebook-rounded.svg');
    }

    &--linkedin {
      background-image: url('~@/assets/social/linkedin-rounded.svg');
    }
  }
}
</style>
