<template>
  <div class="payermax-by-pay">
    <payermax-by-apple
      v-if="isApplePay"
      v-bind="$attrs"
      v-on="listeners"
      :is-staff="isStaff"
      :list-payment="listPayment"
      :amount="amount"
      :fee-checked="feeChecked"
      :fee-amount="feeAmount"
      :bill-id="billId"
      :is-tips="isTips"
      :staff-payout-id="staffPayoutId"
      :workplace-payout-id="workplacePayoutId"
      :total-amount="totalAmount"
      :amplitude-payment-data="amplitudePaymentData"
      :min-tips-amount="minTipsAmount"
      :bill-customer-info="billCustomerInfo"
      :back-url="backUrl"
      :disabled="disabled"
      @submit="handleSubmit"
    />

    <payermax-by-google
      v-if="isGooglePayAvailable"
      v-bind="$attrs"
      v-on="listeners"
      :is-staff="isStaff"
      :list-payment="listPayment"
      :amount="amount"
      :fee-checked="feeChecked"
      :fee-amount="feeAmount"
      :bill-id="billId"
      :is-tips="isTips"
      :staff-payout-id="staffPayoutId"
      :workplace-payout-id="workplacePayoutId"
      :total-amount="totalAmount"
      :amplitude-payment-data="amplitudePaymentData"
      :min-tips-amount="minTipsAmount"
      :bill-customer-info="billCustomerInfo"
      :back-url="backUrl"
      :disabled="disabled"
      :success-url="successUrl"
      :error-url="errorUrl"
      class="payermax-by-google"
    />
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  toRefs,
} from 'vue';
import PayermaxByApple from '@/components/PayermaxByApple.vue';
import PayermaxByGoogle from '@/components/PayermaxByGoogle.vue';
import { isAndroid } from '@/utils/isAndroid';

const props = defineProps({
  listPayment: Array,
  amount: Number,
  feeChecked: Boolean,
  feeAmount: Number,
  staffPayoutId: String,
  workplacePayoutId: String,
  isStaff: Boolean,
  amplitudePaymentData: Object,
  disabled: Boolean,
  minTipsAmount: Number,
  backUrl: Object,
  billId: String,
  isTips: Boolean,
  billCustomerInfo: Object,
  totalAmount: Number,
  successUrl: String,
  errorUrl: String,
});

const {
  isStaff,
  listPayment,
  amount,
  feeChecked,
  disabled,
  workplacePayoutId,
  amplitudePaymentData,
  minTipsAmount,
  billId,
  isTips,
  billCustomerInfo,
} = toRefs(props);

const type = ref(null);

const isApplePay = computed(() => {
  return type.value === 'apple';
});

const checkPaymentMethod = () => {
  if (window.PMdropin.isSupportApplePay()) {
    type.value = 'apple';
  } else {
    type.value = 'google';
  }
};

const loadPayermaxSdk = () => {
  if (window?.PMdropin) {
    checkPaymentMethod();
  } else {
    const script = document.createElement('script');

    script.async = true;
    script.src = 'https://cdn.payermax.com/dropin/js/pmdropin.min.js';
    script.onload = checkPaymentMethod;

    document.head.appendChild(script);
  }
};

const isGooglePayAvailable = ref(false);

const isBill = computed(() => {
  return Boolean(billId.value);
});

const googlePayClientCtor = () => {
  return window.google?.payments?.api?.PaymentsClient;
};

const onGooglePayLoaded = () => {
  if (googlePayClientCtor()) {
    isGooglePayAvailable.value = true;
  }
};

const initGooglePay = () => {
  if (!isAndroid()) {
    return;
  }

  if (googlePayClientCtor()) {
    isGooglePayAvailable.value = true;
  } else {
    const script = document.createElement('script');

    script.async = true;
    script.src = 'https://pay.google.com/gp/p/js/pay.js';
    script.onload = onGooglePayLoaded;

    document.head.appendChild(script);
  }
};

onMounted(() => {
  loadPayermaxSdk();
  initGooglePay();
});
</script>

<style lang="scss">
  .payermax-by-pay {
    display: contents;

    .payermax-by-google {
      width: 100%;
      margin-bottom: 12px;
    }
  }
</style>
