<template>
  <div
    class="socials"
    :class="{ 'socials_hint': !onlyIcons }"
  >
    <p
      class="socials__title"
      v-html="$t('social.text')"
      v-if="!onlyIcons"
    >
    </p>
    <ul class="socials__list">
      <li
        v-for="link in socials"
        :key="link.name"
        class="socials__list__item"
      >
        <a
          :href="link.url"
          class="socials__list__link"
          :class="link.name"
          :alt="link.alt"
          target="_blank"
          @click="$emit('clicked-link', link.name.toUpperCase())"
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  TWITTER_LINK,
} from '@/constants/socials';

export default {
  props: {
    onlyIcons: { type: Boolean },
  },

  data: () => ({
    socials: [
      {
        name: 'instagram',
        alt: 'instagram icon',
        url: INSTAGRAM_LINK,
      },
      {
        name: 'facebook',
        alt: 'facebook icon',
        url: FACEBOOK_LINK,
      },
      {
        name: 'twitter',
        alt: 'twitter icon',
        url: TWITTER_LINK,
      },
      {
        name: 'linkedin',
        alt: 'linkedin icon',
        url: LINKEDIN_LINK,
      },
    ],
  }),
};
</script>

<style lang="scss">
.socials {
  margin-top: 40px;

  &_hint {
    margin-top: 20px;
  }

  &__title {
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
    margin-bottom: 40px;
  }

  &__list {
    margin: 16px 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__item {
      list-style: none;
    }

    &__link {
      display: block;
      margin: 0;
      padding: 0;
      width: 28px;
      height: 28px;
      -webkit-transition: all 300ms ease 0s;
      transition: all 300ms ease 0s;
      background-repeat: no-repeat;

      &.instagram {
        background-image: url('~@/assets/social/instagram-black.svg');
        &:hover {
          background-image: url('~@/assets/social/instagram-color.svg');
        }
      }

      &.facebook {
        background-image: url('~@/assets/social/facebook-black.svg');
        &:hover {
          background-image: url('~@/assets/social/facebook-color.svg');
        }
      }

      &.twitter {
        background-image: url('~@/assets/social/twitter-black.svg');
        &:hover {
          background-image: url('~@/assets/social/twitter-color.svg');
        }
      }

      &.linkedin {
        background-image: url('~@/assets/social/linkedin-black.svg');
        &:hover {
          background-image: url('~@/assets/social/linkedin-color.svg');
        }
      }
    }
  }
}
</style>
