import { trimEnd } from 'lodash';
import api from '@/api';
import countriesConfig from '@/config/countries';
import currenciesConfig from '@/config/currencies';
import tipsConfig from '@/config/tips';
import getFio from '@/utils/getFio';
import i18n from '@/plugins/i18n';
import router from '@/router';
import factory from '../factory';

export default factory({

  namespaced: true,

  state: {
    isLoading: false,
    qrId: '',
    orderId: '',
    locationPayoutId: null,
    staffPayoutIdList: [],
    staffPayoutId: null,
    groupPayoutId: null,
    publicKey: null,
    seokey: null,
    staff: {
      id: null,
      firstName: null,
      lastName: null,
      paymentStatus: null,
      paymentSystem: null,
      wish: null,
      nickName: null,
      paymentMethodsAllowed: [],
    },
    location: {
      id: null,
      name: null,
      wish: null,
      currencyCode: null,
      type: null,
      reviews: [],
      staffs: [],
      groups: [],
      paymentMethodsAllowed: [],
      payButtons: null,
      abVariant: null,
      googleMapsLink: null,
      tripAdvisorLink: null,
      trustPilotLink: null,
      typeOfReview: null,
      paymentSystem: null,
      isMasterLocation: null,
      selectGroupText: null,
      selectStaffText: null,
      selectSubLocationText: null,
      subLocations: [],
      features: [],
      maxTipsAmount: null,
      partner: {
        id: '',
        name: '',
      },
      requestCustomerInfo: false,
      customRedirectUrl: '',
      showTipsModule: true,
      unconditionalFee: false,
    },
    group: {
      id: null,
      name: null,
      staffs: [],
    },
    billInfo: null,
  },

  getters: {
    isQrBill(state) {
      return state.qrId || state.orderId;
    },
    isStaff(state) {
      return Boolean(state.staffPayoutId);
    },
    isLocation(state, getters) {
      return !getters.isStaff;
    },
    isSalon(state) {
      return state.location?.type === 'SPA';
    },
    isGroup(state, getters) {
      return getters.isLocation && getters.hasGroups && Boolean(state.groupPayoutId);
    },
    isGroupList(state, getters) {
      return getters.isLocation && getters.hasGroups && !state.groupPayoutId;
    },
    isStaffList(state, getters) {
      if (getters.isGroup) {
        return true;
      }

      return getters.isLocation && getters.hasList;
    },
    isMasterLocation(state) {
      return state.location.isMasterLocation;
    },
    hasGroups(state) {
      return state.location.paymentPage === 'GROUPS';
    },
    hasList(state) {
      return state.location.paymentPage === 'LIST';
    },
    hasKYC(state) {
      return [
        'ENABLED',
        'COMPLETE',
      ].includes(state.staff.paymentStatus);
    },
    wish(state, getters) {
      return getters.isStaff ? state.staff.wish : state.location.wish;
    },
    name(state, getters) {
      return getters.isStaff ? getFio(state.staff) : state.location.name;
    },
    nickName(state, getters) {
      if (state.staff.nickName) {
        return state.staff.nickName;
      }

      return getters.name;
    },
    countryIso(state) {
      return countriesConfig.find(({ iso, name }) => {
        return [iso, name].includes(state.location.country);
      })?.iso || 'gb';
    },
    currency(state) {
      const iso = state.location.currency?.currencyCode || currenciesConfig.default;
      const label = i18n.global.t(`currency.${iso}`);
      const rightPosition = state.location.currency?.rightPosition;

      return {
        iso,
        label,
        rightPosition,
      };
    },
    tips(state, getters) {
      return (bill) => {
        const tips = [];

        if (bill > 0) {
          tips.push(
            ...tipsConfig.percents[getters.countryIso]
              || tipsConfig.percents.default,
          );
        } else {
          tips.push(
            ...state.location.payButtons?.amountList
              || tipsConfig.amount[getters.countryIso]
              || tipsConfig.amount.default,
          );
        }

        const percentage = bill > 0;

        return tips.map((value) => {
          return {
            percentage,
            label: percentage ? `${value}%` : value,
            value: percentage ? +(bill * value * 0.01).toFixed(2) : value,
          };
        });
      };
    },
    isIndividual(state) {
      return state.location.tipsType === 'PERSONAL';
    },
    isCommonSimple(state) {
      return state.location.tipsType === 'COMMON';
    },
    isCommonAdvanced(state) {
      return state.location.tipsType === 'COMMON_PRIORITY';
    },
    isCommon(state, getters) {
      return getters.isCommonSimple || getters.isCommonAdvanced;
    },
    isUk(state) {
      return state.location.country === 'gb';
    },
    isUAE(state) {
      return state.location.country === 'ae';
    },
    unconditionalFee(state) {
      return state.location.unconditionalFee;
    },
    minTipsAmount(state) {
      return state.location.minTipsAmount;
    },
  },

  actions: {

    /**
     * Refresh if new.
     */
    async loadUser({ state, dispatch }, {
      qrId,
      orderId,
      locationPayoutId,
      staffPayoutIdList,
      staffPayoutId,
      groupPayoutId,
    }) {
      if (
        state.locationPayoutId === locationPayoutId
        && state.staffPayoutId === staffPayoutId
        && state.groupPayoutId === groupPayoutId
        && state.qrId === qrId
        && state.orderId === orderId
      ) {
        return;
      }

      await dispatch('refreshUser', {
        qrId,
        orderId,
        locationPayoutId,
        staffPayoutIdList,
        staffPayoutId,
        groupPayoutId,
      });
    },

    /**
     * Reload all user data.
     * - type
     * - profile data
     * - staff members
     * - reviews
     */
    async refreshUser({
      state,
      commit,
      getters,
      dispatch,
    }, {
      qrId,
      orderId,
      locationPayoutId,
      staffPayoutIdList,
      staffPayoutId,
      groupPayoutId,
    }) {
      commit('reset');
      commit('set', {
        isLoading: true,
        qrId,
        orderId,
        locationPayoutId,
        staffPayoutIdList,
        staffPayoutId,
        groupPayoutId,
      });

      try {
        await dispatch('loadLocation', { payoutId: locationPayoutId });

        if (state.location.status === 'NOT_ACTIVE') {
          await router.push({ name: 'not-active' });
          return;
        }

        // Redirect to old pay and stop next loading
        if (!state.location.isNewApplicationEnabled && process.env.VUE_APP_OLD_URL) {
          window.location.href = window.location.href.replace(
            process.env.VUE_APP_NEW_URL,
            process.env.VUE_APP_OLD_URL,
          );

          // Endless await
          await new Promise(() => {});
          return;
        }

        const parallel = [];

        parallel.push(
          dispatch('loadPublicKey', { payoutId: locationPayoutId }),
          dispatch('loadLocationReviews', { payoutId: locationPayoutId }),
        );

        if (getters.isQrBill) {
          parallel.push(
            dispatch('loadLocationBill', {
              payoutId: locationPayoutId,
              qrId,
              orderId,
            }),
          );
        } else {
          if (getters.isStaff) {
            await dispatch('loadStaff', { payoutId: staffPayoutId });

            // Redirect to payer-max domain and stop next loading
            if (
              state.staff.paymentSystem === 'PAYER_MAX'
              && process.env.VUE_APP_PM_URL
              && !window.location.href.includes(process.env.VUE_APP_PM_URL)
            ) {
              window.location.href = window.location.href.replace(
                window.location.origin,
                process.env.VUE_APP_PM_URL,
              );

              await new Promise(() => {});
              return;
            }

            // Redirect to base domain and stop next loading
            if (
              state.staff.paymentSystem !== 'PAYER_MAX'
              && process.env.VUE_APP_PM_URL
              && window.location.href.includes(process.env.VUE_APP_PM_URL)
            ) {
              window.location.href = window.location.href.replace(
                window.location.origin,
                trimEnd(process.env.VUE_APP_NEW_URL, '/'),
              );

              await new Promise(() => {});
              return;
            }

            parallel.push(
              dispatch('loadSeoKey', { payoutId: staffPayoutId }),
            );
          }

          if (getters.isGroup) {
            parallel.push(
              dispatch('loadGroup', {
                locationId: state.location.id,
                payoutId: groupPayoutId,
              }),
            );
          }

          if (getters.isGroupList) {
            parallel.push(
              dispatch('loadLocationGroups', { payoutId: locationPayoutId }),
            );
          }

          if (getters.isMasterLocation) {
            parallel.push(
              dispatch('loadSubLocations', { payoutId: locationPayoutId }),
            );
          }
        }

        if (
          (!state.location?.staffs?.length
          && !getters.isGroup
          && (!getters.isStaff || staffPayoutIdList?.length > 1))
          || getters.isQrBill
        ) {
          parallel.push(
            dispatch('loadLocationStaffs', { payoutId: locationPayoutId }),
          );
        }

        parallel.push(
          dispatch('loadLocationFeatures', { payoutId: locationPayoutId }),
        );

        await Promise.all(parallel);
      } finally {
        commit('set', {
          isLoading: false,
        });
      }
    },

    /**
     * Load seo key.
     */
    async loadSeoKey({ commit }, { payoutId }) {
      try {
        const {
          data: {
            footerInfo,
          },
        } = await api.getSeoKey(payoutId);

        commit('set', {
          seokey: footerInfo,
        });
      } catch (e) {
        commit('set', {
          seokey: null,
        });
      }
    },

    /**
     * Load stripe public key.
     */
    async loadPublicKey({ commit }, { payoutId }) {
      try {
        const {
          data: {
            publicKey,
          },
        } = await api.getPublicKey(payoutId);

        commit('set', {
          publicKey,
        });
      } catch (e) {
        commit('set', {
          publicKey: null,
        });
      }
    },

    /**
     * Load staff profile data.
     */
    async loadStaff({ commit }, { payoutId }) {
      const {
        data: {
          workplace,
          ...staff
        },
      } = await api.getStaff(payoutId);

      commit('set', {
        staff,
        // location: workplace, 12.08.2024 (https://easytip.myjetbrains.com/youtrack/issue/EASYTIP-7223)
      });
    },

    /**
     * Load location profile data.
     */
    async loadLocation({ state, commit }, { payoutId }) {
      const { data } = await api.getLocation(payoutId);
      const { id, staffs } = state.location;

      if (id === data.id) {
        Object.assign(data, {
          staffs,
        });
      }

      commit('set', {
        location: data,
      });
    },

    /**
     * Load location active staff members.
     */
    async loadLocationStaffs({ commit }, { payoutId }) {
      const { data } = await api.getLocationStaffs(payoutId);

      commit('set', {
        location: {
          staffs: data,
        },
      });
    },

    /**
     * Load location features.
     */
    async loadLocationFeatures({ commit }, { payoutId }) {
      const { data } = await api.getFeatures(payoutId);

      commit('set', {
        location: {
          features: data,
        },
      });
    },

    /**
     * Load location groups.
     */
    async loadLocationGroups({ commit }, { payoutId }) {
      const { data } = await api.getLocationGroups(payoutId);

      commit('set', {
        location: {
          groups: data,
        },
      });
    },

    /**
     * Load sub locations.
     */
    async loadSubLocations({ commit }, { payoutId }) {
      const { data } = await api.getSubLocations(payoutId);

      commit('set', {
        location: {
          subLocations: data,
        },
      });
    },

    /**
     * Load location groups.
     */
    async loadGroup({ commit }, { locationId, payoutId }) {
      const { data } = await api.getGroup(locationId, payoutId);

      commit('set', {
        group: data,
      });
    },

    /**
     * Load location reviews (compliments/issues).
     */
    async loadLocationReviews({ commit }, { payoutId }) {
      const { data } = await api.getLocationReviews(payoutId);

      commit('set', {
        location: {
          reviews: data,
        },
      });
    },

    /**
     * Load location bill.
     */
    async loadLocationBill({ state, commit }, { payoutId, qrId, orderId }) {
      const { name } = state.location;

      try {
        const { data } = await api.getLocationBill(payoutId, qrId, orderId);

        commit('set', {
          billInfo: data,
        });
      } catch (e) {
        router.push({
          name: 'bill-not-found',
          query: {
            locationPayoutId: payoutId,
            locationName: name,
          },
        });
      }
    },
  },
});
